import React, { useEffect, useRef } from 'react'
import { useAppState } from '../AppContext'
import { dropdownMenuAvatar, dropdownMenuServico } from './actions'
import { logoutUser } from '../../commons/logged-user/actions'

function useOutsideAlerter(ref) {
  const [state, dispatch] = useAppState()
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(dropdownMenuAvatar(false))
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function Avatar() {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const [state, dispatch] = useAppState()

  const dropdown = () => {
    dispatch(dropdownMenuAvatar(!state.layout.avatar))
    dispatch(dropdownMenuServico(false))
  }

  return (
    <div>
    </div>
  )
}

export default Avatar
