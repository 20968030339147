import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Message from './Message.js'
import Login from '../home/Login';
function Content() {
  return (
    <main>
      <Message />
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
      </Switch>
    </main>
  )
}

export default Content
