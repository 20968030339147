import fetchApi from "../fetch-api";
import { API_PATH } from "../constants";
export const CHANGE_LOGGED_USER_ACTION = "USER/CHANGE_LOGGED_USER";

export const changeLoggedUserData = user => ({
  type: CHANGE_LOGGED_USER_ACTION,
  user
});

export const fetchLoggedUser = () => async dispatch => {
  let user = await fetchApi(API_PATH.endpoints.home, { method: "GET" });

  if (user && user.sub !== undefined) {
    user.id = user.sub || user.id; //Faz uma simples tradução, pois o sub só eh retornado na chamada ao /api
  }
  dispatch(changeLoggedUserData(user));
};

export const logoutUser = () => async dispatch => {
  window.location.href = API_PATH.endpoints.logout;
};
