import React, { useEffect } from "react";
import { useAppState, useQuery } from "../AppContext";
import { changeMessageDelay } from "../layout/actions";
import {
  changeFormData,
  validatePassword,
  getClientInfo,
  logout
} from "./actions";

const componentDidMount = dispatch => () => {
  dispatch(getClientInfo());
};

function Login() {
  const [state, dispatch] = useAppState();
  const { formData } = state;
  const [{ client }] = useAppState();
  useEffect(componentDidMount(dispatch, state), []);

  const validate = password => {
    if (password === undefined || password.length === 0) {
      dispatch(
        changeMessageDelay({ type: "ERROR", text: "Informe sua senha" })
      );
      return;
    }
    dispatch(validatePassword(password));
  };

  const back = () => {
    sessionStorage.clear();
    window.location.href = client.homePage;
  };

  return (
    <div style={{ width: "100%" }}>
      {client && client.name && (
        <div id="card" className="card">
          <div style={{ width: "100%" }}>
            <img
              src={`${process.env.REACT_APP_URL_ASSETS}/images/govbr.png`}
              alt="Logomarca GovBR"
            />
            <h2>Confirme sua identidade gov.br</h2>

            <input
              value={formData.password}
              type="password"
              placeholder="Digite a sua senha"
              onChange={e => {
                dispatch(
                  changeFormData({ ...formData, password: e.target.value })
                );
              }}
              autoFocus={true}
            />
            <label htmlFor="password">
              <strong>{client.name}</strong> deseja que você confirme sua senha
              do gov.br para prosseguir. Após validar sua senha, você será
              redirecionado.
            </label>
            <div className="action-group">
              <div className="action-group-left">
                <button className="action-tertiary" onClick={() => back()}>
                  Voltar
                </button>
              </div>
              <div>
                <button
                  className="action-primary"
                  type="submit"
                  onClick={() => validate(formData.password)}
                >
                  Confirmar Senha
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
