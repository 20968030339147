export const API_URL = `${window.location.protocol}//${window.location.hostname}`;
export const API_PATH = {
  endpoints: {
    start: `${API_URL}/api/login-openid`,
    home: `${API_URL}/api/`,
    logout: `${API_URL}/api/logout`,
    getUserInfo: `${API_URL}/api/userinfo`,
    postPassword: `${API_URL}/api/:accountId/password`,
    getClientInfo: `${API_URL}/api/:accountId/client-info`,
    putConsent: `${API_URL}/v1/consent`
  }
};

export const INITIAL_STATE = {
  aux_store: {},
  logged_user: {
    id: "",
    name: "",
    email: "",
    emailVerified: "",
    phoneNumber: "",
    phoneNumberVerified: "",
    amr: "",
    cnpj: "",
    photoBase64: ""
  },
  layout: {
    loading: false,
    title: "",
    breadcrumbs: [],
    message: {}
  },
  client: {},
  formData: {
    formData: {}
  }
};
