import { API_PATH } from "../../commons/constants";
import fetchApi from "../../commons/fetch-api";
import { changeMessageDelay } from "../layout/actions";

export const CHANGE_CLIENT_ACTION = "USER/CHANGE_CLIENT_ACTION";
export const FORM_DATA = "FORM_DATA";

export const validatePassword = password => async (dispatch, state) => {
  const pathRequest = API_PATH.endpoints.postPassword.replace(
    ":accountId",
    state.logged_user.id
  );

  const response = await fetchApi(pathRequest, {
    method: "POST",
    body: JSON.stringify({ password: password })
  });

  if (response && response.redirectionUrl) {
    sessionStorage.clear();
    window.location.href = response.redirectionUrl;
  }
};

export const getClientInfo = () => async (dispatch, state) => {
  const pathRequest = API_PATH.endpoints.getClientInfo.replace(
    ":accountId",
    state.logged_user.id
  );
  const response = await fetchApi(pathRequest, { method: "GET" });

  if (response) {
    dispatch(changeClientData(response));
  }
};

export const logout = () => async dispatch => {
  sessionStorage.clear();
  window.location.href = API_PATH.endpoints.logout;
};

export const changeClientData = client => ({
  type: CHANGE_CLIENT_ACTION,
  client
});

export const changeFormData = data => ({
  type: FORM_DATA,
  data
});
