import {
  CHANGE_CLIENT_ACTION,
  FORM_DATA,
} from './actions'

export const clientReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_CLIENT_ACTION:
      return { ...state, ...action.client };
    default:
      return state;
  }
};


export const changeFormDataReducer = (state, action) => {
  switch (action.type) {
    case FORM_DATA:
      return { ...state, ...action.data }
    default:
      return state
  }
}




